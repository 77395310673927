<template>
  <v-card
      :tile="textCard"
      elevation="1"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      min-height="280">
    <MainModal
        :main="{ component: 'PrivilegeOwner', title: 'Пільгове посвідчення' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_privilege_owner)"
        :nonClickable="nonClickable"
        :modal="show_privilege_owner_dialog"
        @updateItemModal="privilegeOwnerUpdateItemModal"
    />
    <v-card-text class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік пільг
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-1"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/xRrPLuW1OYY"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="ml-2 mr-2 grey lighten-4" v-on="on" v-bind="attrs" @click.stop="">
              <v-icon color="grey darken-2" size="22">mdi-comment-question-outline</v-icon>
            </v-btn>
          </template>
          <span>
            Для створення пільгового посвідчення <br> необхідно виконати команду "Створити"
          </span>
        </v-tooltip>
        <v-tooltip bottom color="success" v-if="!readOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openPrivilegeOwnerCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нове пільгове посвідчення</span>
        </v-tooltip>
<!--        <MainModal-->
<!--            :main="{ component: 'PrivilegeOwner', title: 'Пільгове посвідчення' }"-->
<!--            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"-->
<!--            :item="{flat_hash: current_flat_hash, personal: personal}"-->
<!--            :tooltip="{active: true, title: 'Створити нове пільгове посвідчення'}"-->
<!--            @updateItemModal="PrivilegeUpdateItemModal"-->
<!--            v-if="!readOnly"-->
<!--            :nonClickable="nonClickable"-->
<!--        />-->
      </v-toolbar>
    </v-card-text>
    <v-data-table
        :headers="familyHeaders"
        :items="items"
        hide-default-footer
        :class="bigger ? 'custom-table-bigger' : ''"
        class="custom-table custom-table-1"
        @click:row="onPrivilegeOwnerItemClick"
    >
      <template v-slot:item.selected="{ item }">
        <v-simple-checkbox color="secondary" :value="selected === item.id" :ripple="false" @input="changeSelectPrivilegeOwner(item)"/>
      </template>
      <template v-slot:item.icon>
        <v-icon :color="'success'" size="26" class="mr-2">
          {{ 'mdi-card-account-details-outline' }}
        </v-icon>
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ item.date_end | formatDate }}
      </template>
      <template v-slot:item.create_date="{ item }">
        {{ item.create_date | formatDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {CLEAR_SELECTED_PRIVILEGE, FETCH_PRIVILEGE_OWNER, SET_SELECTED_PRIVILEGE} from "@/store/actions/privilege";

export default {
  name: "HWP_PrivilegeOwner",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Поточний стан розрахунку по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    bigger: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      items: 'getPrivilegeOwners'
    }),
    current_flat_hash() {
      let person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      if (person_hash) {
        return person_hash
      } else {
        return this.person_hash
      }
    }
  },
  data() {
    return {
      familyHeaders: [
        { text: '', value: 'selected', width: 60 },
        { text: '', value: 'icon', width: 60 },
        { text: 'П.І.Б', value: 'privilege_owner_name' },
        { text: 'Категорія', value: 'privilege_category_name' },
        { text: 'Порядок', value: 'order', width: 110 },
        { text: '№ посвідчення', value: 'privilege_number' },
        { text: 'Дата з', value: 'date_start' },
        { text: 'Дата по', value: 'date_end' },
        { text: 'Дата створення', value: 'create_date' }
      ],
      selected_privilege_owner: {},
      show_privilege_owner_dialog: false,
      selected: false
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_PRIVILEGE_OWNER,
    }),
    changeSelectPrivilegeOwner(item) {
      if (item.id === this.selected) {
        this.selected = 0
        this.$store.commit(CLEAR_SELECTED_PRIVILEGE)
      } else {
        this.selected = item.id
        this.$store.commit(SET_SELECTED_PRIVILEGE, item)
      }
    },
    privilegeOwnerUpdateItemModal() {
      this.show_privilege_owner_dialog = false
      this.selected_privilege_owner = {}
    },
    openPrivilegeOwnerCreateDialog() {
      this.selected_privilege_owner = {
        flat_hash: this.current_flat_hash,
        personal: this.personal
      }
      this.show_privilege_owner_dialog = true
    },
    onPrivilegeOwnerItemClick(payload) {
      if (this.readOnly || this.nonClickable) {
        this.selected_privilege_owner = {}
        this.show_privilege_owner_dialog = false
      }
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_hash = this.current_flat_hash
      local_payload.personal = this.personal
      local_payload.privilege_order = local_payload.order

      this.selected_privilege_owner = local_payload
      this.show_privilege_owner_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetch(person_hash)
      this.$store.commit(CLEAR_SELECTED_PRIVILEGE)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetch(payload)
          this.$store.commit(CLEAR_SELECTED_PRIVILEGE)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table-bigger {
  :deep(.v-data-table__wrapper) {
    tbody {
      tr {
        td {
          height: 40px !important;
        }
      }
    }
    thead {
      tr {
        th {
          height: 46px !important;
        }
      }
    }
  }
}
</style>
